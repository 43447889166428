<template>
	<div class="full-height">
		<div
			class="full-height flex-column overflow-y-auto mb-10"
		>
			<div
				class="justify-space-between"
			>
				<div class="flex-1 bg-white pa-10 mr-10">
					<div class="justify-space-between items-center">
						<h6 class="under-line-identify">계정정보</h6>
						<div>
							<button
								v-if="item.agency_type == 'A001003' && is_wallet"
								@click="copy"
								class="btn-inline btn-primary size-px-11"
							>가입 코드 발급</button>
						</div>
					</div>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
							<tr>
								<th>영업단 구분 <span class="color-red">*</span></th>
								<td>{{ item.agency_type_name }}</td>
								<th>소속 영업점 <span class="color-red">*</span></th>
								<td>
									<template
										v-if="user.account_type == 'A001001'"
									>
										<template
											v-if="item.agency_type === codes.A001.items[2].total_code"
										>
										<select
											v-if="items_upper.length > 0"
											v-model="item.agency_upper"

											class="input-box"
											@change="getPgList"
										>
											<option value="">선택하세요</option>
											<option
												v-for="(upper, index) in items_upper"
												:key="upper.uid + '_' + index"
												:value="upper.uid"
											>{{ upper.agency_name }}</option>
										</select>
										<template
											v-else
										>영업단 구분을 선택하세요</template>
										</template>
										<template
											v-else
										>{{ item.agency_upper_name }}</template>
									</template>
									<template
										v-else
									>{{ user.shop_name }}</template>
								</td>
							</tr>
							<tr>
								<th>아이디  <span class="color-red">*</span></th>
								<td>{{ item.agency_id }}</td>
								<th>비밀번호</th>
								<td class="text-right">
									<button
										class="bg-identify pa-5-10"
										@click="isPassword"
									>비밀번호 초기화</button>
								</td>
							</tr>
							<tr>
								<th>상호명 <span class="color-red">*</span></th>
								<td>
									<input
										type="text"
										v-model="item.account_name"
										maxlength="25"
										class="input-box full-width"
										placeholder="이름을 입력하세요"
									/>
								</td>
								<th>휴대폰 번호 <span class="color-red">*</span></th>
								<td>
									<input
										type="number"
										v-model="item.account_phone_number"
										:ruels="[rules.max(item, 'account_phone_number', 12)]"
										class="input-box full-width"
										placeholder="휴대폰번호를 입력하세요"
									/>
								</td>
							</tr>
							<tr>
								<th>가입일</th>
								<td colspan="3" class="position-relative">
									<input
										v-model="item.join_date"
										class="input-box-inline"
										placeholder="가입일을 선택하세요"
										readonly
										@click="is_data_pick = !is_data_pick"
									/>
									<v-date-picker
										v-if="is_data_pick"
										v-model="item.join_date"
										no-title
										scrollable
										class="position-absolute box"
										style="top: 45px; left: -5px"
										@change="is_data_pick = false"
									></v-date-picker>
								</td>
							</tr>
							<tr>

								<th>계정 상태</th>
								<td colspan="3">
									<button
										v-for="(code, index) in codes.S003.items"
										:key="code.total_code + '_' + index"

										class="pa-5-10 mr-1"
										:class="item.account_status == code.code_value ? 'bg-' + code.code_color: 'bg-default'"
										@click="item.account_status = code.code_value "
									>{{ code.code_name }}</button>
								</td>
							</tr>
						</tbody>
					</table>

					<h6 class="mt-10 under-line-identify">상점 정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr
							v-if="false && item.agency_type == 'A001003'"
						>
							<th>표기 구분 <span class="color-red">*</span></th>
							<td colspan="3">
								<button
									v-for="(summary, s_index) in items_summary"
									:key="'summary_' + s_index"
									class="btn-inline"
									:class="summary == item.shop_summary ? 'bg-green': 'bg-default'"
									@click="item.shop_summary = summary"
								>{{  summary }}</button>
							</td>
						</tr>
						<tr
							v-if="item.agency_type == 'A001003'"
						>
							<th>상점 로고 <br/> 150 x 100</th>
							<td colspan="3">
								<div>
									<label
										class="box pa-10 justify-space-between"
									>
										{{ logo_img_name }}
										<v-icon
											class="color-icon"
										>mdi mdi-image</v-icon>

										<input_file
											v-show="false"
											accept="image/*" @change="setFile2"
										/>
									</label>
								</div>

								<div
									v-if="item_upload_logo_img.src"
									class="flex-row mt-10"
								>
									<div
										class="flex-1" style="position: relative"
									>
										<img
											:src="item_upload_logo_img.src"
										/>
										<button class="item_close" style="background-color: black">
											<v-icon
												@click="removeDelivery"
											>mdi-close</v-icon>
										</button>
									</div>
									<div class="flex-3 flex-column justify-center ml-10" style="word-break: break-all;">
										<p>{{  item_upload_logo_img.name }}</p>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<th>사업자 구분</th>
							<td colspan="3">
								<button
									v-for="(code, index) in codes.B002.items"
									:key="code.total_code + '_' + index"

									class="pa-5-10"
									:class="item.business_type == code.total_code ? 'bg-green' : 'bg-default'"

									@click="item.business_type = code.total_code"
								>{{ code.code_name }}</button>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002002'"
						>
							<th>사업자등록번호</th>
							<td colspan="3">
								<input
									type="number"
									v-model="item.front"
									:ruels="[rules.max(item, 'front', 10)]"
									class="input-box-inline"
									placeholder="사업자 등록 번호"
								/>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002001'"
						>
							<th>사업자등록번호</th>
							<td>
								<input
									type="number"
									v-model="item.front"
									:ruels="[rules.max(item, 'front', 10)]"
									class="input-box-inline"
									placeholder="사업자 등록 번호"
								/>
							</td>
							<th>법인등록번호</th>
							<td>
								<input
									type="number"
									v-model="item.back"
									:ruels="[rules.max(item, 'back', 13)]"
									class="input-box-inline"
									placeholder="법인 등록 번호"
								/>
							</td>
						</tr>
						<tr
							v-if="item.business_type == 'B002003'"
						>
							<th>주민등록 번호</th>
							<td colspan="3">
								<input
									v-model="item.front"
									type="number"
									:ruels="[rules.max(item, 'front', 6)]"
									class="input-box-inline mr-10"
									placeholder="주민등록 앞번호"
								/>
								<input
									v-model="item.back"
									type="password"
									maxlength="7"
									class="input-box-inline"
									placeholder="주민등록 뒷번호"
								/>
							</td>
						</tr>
						<tr>
							<th>사업장 주소</th>
							<td colspan="3">

								<input
									v-model="item.shop_zip_code"

									class="input-box-inline mr-10"
									type="text" placeholder="우편번호"
									readonly

									@click="daumPost('company')"
								>

								<button
									class="box pa-5-10 bg-identify"
									@click="daumPost('company')"
								>주소 검색</button>

								<input
									v-model="item.shop_address"

									class="input-box full-width mt-10" type="text" placeholder="주소"
									readonly

									@click="daumPost('company')"
								>
								<input
									v-model="item.shop_address_detail"

									class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
									maxlength="50"
								>
							</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div class="flex-1 bg-white pa-10">

					<div
						v-if="item.agency_type == 'A001003'"
					>
						<h6>정산 정보</h6>
						<table class="table th-left td-left">

							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
							<tr>
								<th>정산 방식 <span class="color-red">*</span></th>
								<td class="text-left" colspan="3">
									<button
										v-for="(code, index) in codes.settlement_type"
										:key="code.code + '_' + index"

										class="pa-5-10 mr-1"
										:class="item.settlement_type == code.code ? 'bg-success': 'bg-default'"
										@click="item.settlement_type = code.code "
									>{{ code.name }}</button>
								</td>
							</tr>
							<template
								v-if="item.settlement_type == 'bank'"
							>
								<tr>
									<th>은행 <span class="color-red">*</span></th>
									<td>
										<select
											v-model="item.bank_code"
											class="input-box"
										>
											<option :value="''">은행을 선택하세요</option>
											<option
												v-for="code in codes.B001.items"
												:key="code.total_code"
												:value="code.total_code"
											>{{ code.code_name}}</option>
										</select>
									</td>
									<th>계좌번호 <span class="color-red">*</span></th>
									<td>
										<input
											v-model="item.bank_account"
											type="number"
											class="input-box full-width"
											:rules="[rules.max(item, 'bank_account', 25)]"
											placeholder="정산 계좌 번호"
										/>
									</td>
								</tr>

								<tr>
									<th>예금주 <span class="color-red">*</span></th>
									<td>
										<input
											v-model="item.bank_holder"
											class="input-box full-width"
											placeholder="정산 계좌 예금주"
											maxlength="15"
										/>
									</td>
									<th>이메일 <span class="color-red">*</span></th>
									<td>
										<input
											v-model="item.bank_email"
											type="email"
											class="input-box full-width"
											placeholder="세금계산서 발행 이메일"
											maxlength="50"
										/>
									</td>
								</tr>
							</template>
							</tbody>
						</table>
					</div>

					<h6 class=" under-line-identify">서비스 정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr>
							<th>출금 서비스<br/> 이용 상태 <span class="color-red">*</span></th>
							<td colspan="3">
								<button
									v-for="(code, index) in codes.S004.items"
									:key="code.total_code + '_' + index"

									class="pa-5-10 mr-1"
									:class="item.sales_status == code.code_value ? 'bg-' + code.code_color: 'bg-default'"
									@click="item.sales_status = code.code_value "
								>{{ code.code_name }}</button>
							</td>
						</tr>
						<tr>
							<th>서비스 구분 <span class="color-red">*</span></th>
							<td colspan="3">
								<button
									v-if="is_on_wallet"
									class="mr-10"
									@click="is_wallet = !is_wallet"
								>
									<v-icon
										v-if="is_wallet"
										class="color-blue"
									>mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
									지갑
								</button>
								<button
									v-if="is_on_api"
									class="mr-10"
									@click="is_api = !is_api"
								>
									<v-icon
										v-if="is_api"
										class="color-blue"
									>mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
									지갑 API
								</button>
								<button
									v-if="is_on_payment"
									@click="is_payment = !is_payment; !is_payment ? is_self = false : ''"
								>
									<v-icon
										v-if="is_payment"
										class="color-blue"
									>mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
									수기결제
								</button>

								<button
									v-if="is_on_payment"
									@click="is_self = !is_self"
									:disabled="!is_payment"
								>
									<v-icon
										v-if="is_self"
										class="color-blue"
									>mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
									카드 인증
								</button>

							</td>
						</tr>

						<tr>
							<th>서비스명 <span class="color-red">*</span></th>
							<td colspan="3">
								<input
									type="text"
									v-model="item.shop_name"
									maxlength="25"
									class="input-box-inline"
									placeholder="서비스명을 입력하세요"
								/>
							</td>
						</tr>
						<tr
							v-if="item.agency_type == 'A001002'"
						>
							<th>SMS</th>
							<td>
								<select
									v-model="item.sms_uid"
									class="input-box"
								>
									<option value="">선택하세요</option>
									<option
										v-for="(sms, sms_index) in items_sms"
										:key="'sms_' + sms_index"
										:value="sms.uid"
									>{{ sms.sms_name}}</option>
								</select>
							</td>
							<th></th>
							<td></td>
						</tr>

						<tr
							v-if="(is_wallet || is_api) && item.agency_type == 'A001002'"
						>
							<th>가상계좌</th>
							<td>
								<select
									v-model="item.virtual_uid"
									class="input-box"
								>
									<option>가상계좌를 선택하세요</option>
									<option
										v-for="(virtual, v_index) in items_virtual"
										:key="'virtual_' + v_index"
										:value="virtual.uid"
									>{{ virtual.virtual_name}}</option>
								</select>
							</td>
							<th><!-- 관리 권한 --></th>
							<td>
								<button
									v-if="false"
									@click="is_manage = !is_manage"
								>
									<v-icon
										v-if="is_manage"
										class="color-blue"
									>mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
									가상계좌 관리
								</button>
							</td>
						</tr>

						<tr
							v-if="false && item.agency_type == 'A001002'"
						>
							<th>연결 도메인</th>
							<td colspan="3">

								<input
									v-model="item.shop_link"
									class="input-box full-width"
									placeholder="도메인을 입력하세요"
								/>
							</td>
						</tr>

						<template
							v-if="is_wallet || is_api"
						>
							<tr
							>
								<th>1회 출금 한도 <span class="color-red">*</span></th>
								<td colspan="3">
									<input
										type="number"
										v-model="item.payment_limit"
										maxlength="20"
										class="input-box-inline mr-10"
										placeholder="1회 출금 한도"
										:rules="[rules.max(item, 'payment_limit', 9)]"
									/>
									<button
										class="mr-10"
										@click="item.is_payment_limit = item.is_payment_limit == 0 ? 1 : 0"
									>
										<v-icon
											v-if="item.is_payment_limit > 0"
											class="color-blue"
										>mdi-checkbox-marked</v-icon>
										<v-icon
											v-else
										>mdi-checkbox-blank-outline</v-icon>
										출금 한도 적용
									</button>
								</td>
							</tr>
							<tr>
								<th rowspan="2">출금 <template v-if="item.agency_type == 'A001003'">최종</template>수수료 <span class="color-red">*</span></th>
								<td>

									<button
										class="btn-inline"

										@click="item.sales_fee_type = '0'"
										:class="item.sales_fee_type == '0' ? 'btn-success' : 'btn-default'"
									>출금 건 별</button>
									<button
										v-if="false"
										class="btn-inline"
										@click="item.sales_fee_type = '1'"
										:class="item.sales_fee_type == '1' ? 'btn-success' : 'btn-default'"
									>출금액 %</button>
								</td>
								<th rowspan="2"></th>
								<td></td>
								<template v-if="false">
								<th rowspan="2">입금 <template v-if="item.agency_type == 'A001003'">최종</template>수수료 <span class="color-red">*</span></th>
								<td>
									<button
										v-if="false"
										class="btn-inline"

										@click="item.deposit_fee_type = '0'"
										:class="item.deposit_fee_type == '0' ? 'btn-success' : 'btn-default'"
									>입금 건 별</button>
									<button
										class="btn-inline"
										@click="item.deposit_fee_type = '1'"
										:class="item.deposit_fee_type == '1' ? 'btn-success' : 'btn-default'"
									>입금액 %</button>
								</td>
								</template>
							</tr>
							<tr>
								<td>
									<input
										v-if="item.sales_fee_type == '0'"
										v-model="item.sales_fee_base"
										class="input-box full-width"
										placeholder="출금 건별 수수료"
									/>
									<input
										v-if="item.sales_fee_type == '1'"
										v-model="item.sales_fee"
										class="input-box full-width"
										placeholder="출금 수수료율"
									/>
								</td>
								<td></td>
								<template
									v-if="false"
								>
								<td>
									<input
										v-if="item.deposit_fee_type == '0'"
										v-model="item.deposit_fee"
										class="input-box-inline"
										placeholder="입금 건별 수수료"
									/>
									<input
										v-else
										v-model="item.deposit_fee_per"
										class="input-box-inline"
										placeholder="입금 수수료율"
									/>
								</td>
								</template>
							</tr>
						</template>

						<tr
							v-if="is_payment"
						>
							<th>1회 결제 한도</th>
							<td colspan="3">
								<input
									type="number"
									v-model="item.payment_limit"
									maxlength="20"
									class="input-box-inline mr-10"
									placeholder="1회 결제 한도"
									:rules="[rules.max(item, 'payment_limit', 8)]"
								/>
								<button
									class="mr-10"
									@click="item.is_payment_limit = item.is_payment_limit == 0 ? 1 : 0"
								>
									<v-icon
										v-if="item.is_payment_limit > 0"
										class="color-blue"
									>mdi-checkbox-marked</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-outline</v-icon>
									결제 한도 적용
								</button>
							</td>
						</tr>
						</tbody>
					</table>

					<div
						v-if="item.agency_type == 'A001003' && (is_wallet || is_api)"
						class="mt-10"
					>
						<h6>대리점 전용</h6>

						<table
							v-if="is_api"
							class="table"
						>
							<col width="120px">
							<col width="auto">
							<tbody>
							<template
								v-if="item_wallet.uid"
							>
								<tr>
									<th>지갑 주소</th>
									<td class="text-left">{{ item_wallet.virtual_account ? item_wallet.virtual_account : '-' }}</td>
								</tr>
								<tr>
									<th>잔액</th>
									<td class="text-left">
										{{ item_wallet.balance | makeComma}} 원

										<button
											class="btn-primary"
											@click="postRefresh"
										><v-icon small class="color-white">mdi mdi-refresh</v-icon></button>
									</td>
								</tr>
							</template>
							<template
								v-else
							>
								<tr>
									<th class="text-left" rowspan="6">지갑</th>
									<td class="text-left">
										<button
											@click="postAgencyWallet"
											class="btn-inline btn-primary"
										>지갑 생성</button>
									</td>
								</tr>
							</template>
							</tbody>
						</table>
						<table
							v-if="is_api"
							class="table th-left td-left"
						>
							<col width="120px">
							<col width="auto">
							<col width="120px">
							<col width="auto">
							<tbody>
								<tr
									v-if="item.api_key"
								>
									<th>API ID</th>
									<td>{{ item.api_id }}</td>
									<th>API KEY</th>
									<td>{{ item.api_key }}</td>
								</tr>
								<tr
									v-else
								>
									<th>API KEY</th>
									<td class="text-left" colspan="3">
										<span
											v-if="item.api_key"
										>{{ item.api_key}}</span>
										<button
											v-else
											class="btn-inline btn-primary"
											@click="postKey"
											:disabled="!items_ip[0]?.api_ip || !item.api_limit"
										>API KEY 발급</button>
									</td>
								</tr>
								<tr>
									<th>
										<div class="justify-space-between items-center">
										API IP
											<button
												@click="addIp"
											><v-icon class="color-primary">mdi mdi-plus-circle</v-icon></button>
										</div>
									</th>
									<td class="text-left">
										<ul>
											<li
												v-for="(ip, i_index) in items_ip"
												:key="'ip_' + i_index"
												class="justify-space-between mb-10"
											>
												<input v-model="ip.api_ip" class="input-box mr-5 " placeholder="IP를 입력하세요"/>
												<button
													@click="removeIp(i_index)"
												><v-icon class="color-red">mdi mdi-minus-circle</v-icon></button>
											</li>
										</ul>
									</td>
									<th>API 연결 제한</th>
									<td class="text-left">
										<input v-model="item.api_limit" class="input-box-inline mr-5" placeholder="연결제한 수량을 입력하세요"/>
									</td>
								</tr>

								<tr
									v-if="item.api_key"
								>
									<th>사용 여부</th>
									<td>
										<button
											@click="$set(item, 'api_status', item.api_status == 's' ? 'w' : 's')"
										>
											<v-icon
												v-if="item.api_status == 's'"
												class="color-blue"
											>mdi-checkbox-marked</v-icon>
											<v-icon
												v-else
											>mdi-checkbox-blank-outline</v-icon>

											API 사용
										</button>
									</td>
									<th>계좌 발급</th>
									<td>{{ item.api_count | makeComma}}건</td>
								</tr>
								<tr>
									<th>Return URL</th>
									<td colspan="3"><input v-model="item.noti_url" class="input-box mr-5" placeholder="https://test.com/noti"/></td>
								</tr>
							</tbody>
						</table>

						<table
							v-if="is_wallet"
							class="table"
						>
							<col width="120px">
							<col width="auto">
							<tbody>
							<template
								v-if="item_deposit.member_id"
							>
								<tr>
									<th>지갑 아이디</th>
									<td class="text-left">{{ item_deposit.member_id }}</td>
								</tr>
								<tr>
									<th>지갑 주소</th>
									<td class="text-left">{{ item_deposit.wallet_account ? item_deposit.wallet_account : '-' }}</td>
								</tr>
								<tr>
									<th>잔액</th>
									<td class="text-left">{{ item_deposit.balance | makeComma}} 원</td>
								</tr>
							</template>
							<template
								v-else
							>
								<tr>
									<th rowspan="6">입금 지갑</th>
									<td class="text-left">
										<button
											@click="postAgencyDepositWallet"
											class="btn-inline btn-primary"
										>지갑 계정 생성</button>
									</td>
								</tr>
								<tr>
									<td class="text-left">1. 지갑 계정 생성 후 회원 목록에서 지갑 전용 계정 정보를 변경하셔야 합니다</td>
								</tr>
								<tr>
									<td class="text-left">2. 이름 / 휴대폰 번호 / 생년월일을 사용하고자 하는 대리점 사용자의 실제 정보와 동일하게 적용되어야 합니다 </td>
								</tr>
								<tr>
									<td class="text-left">3. 초기 비밀번호는 해당 대리점의 비밀번호와 동일합니다 </td>
								</tr>
								<tr>
									<td class="text-left">4. 페이트리 지갑에서 발급된 계정으로 접속 후 본인 인증 완료시 전용 계좌가 발급됩니다 </td>
								</tr>
								<tr>
									<td class="text-left">5. 전용 계좌로 입금된 금액은 지갑 잔액으로 반영되며 실제 출금이 가능합니다   </td>
								</tr>
							</template>
							</tbody>
						</table>
					</div>

					<div
						v-if="is_payment"
						class="mt-10"
					>
						<h6>분리 결제 PG 설정</h6>
						<table
							class="table"
						>
							<thead>
							<tr>
								<th>PG 명</th>
								<th
									v-if="user.role == 'admin'"
								>PG 원가</th>
								<th>마진율</th>
								<th>공급가</th>
								<th>순서</th>
								<th>딜레이</th>
								<th>비율</th>
								<th>적용여부</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td
									:colspan="colspan_margin"
									class="bg-base"
								>수기 결제 {{ sum_per }}</td>
							</tr>
							<tr
								v-for="(pg, index) in pg_sales_list"
								:key="'pg_' + index"
							>
								<td>{{ pg.pg_name }}</td>
								<td
									v-if="user.role == 'admin'"
								>{{ pg.pg_fee }}%</td>
								<td>
									<input
										v-model="pg.fee_rate"
										type="number"
										:rules="[rules.demical(pg, 'fee_rate', { min: 2, max: 2})]"
										class="box pa-5-10 width-fee "
									/> %
								</td>
								<td>
									<input
										v-model="pg.total_rate"
										class="box pa-5-10 width-fee "
										type="number"
										disabled
									/> %
								</td>
								<td>
									<input
										v-model="pg.payment_sort"
										class="box pa-5-10 width-fee "
										type="number"
										:disabled="pg.fee_status != 1"
										:rules="$rules.max(pg, 'payment_sort',2)"
									/>
								</td>
								<td>
									<input
										v-model="pg.payment_delay"
										class="box pa-5-10 width-fee "
										type="number"
										placeholder=""
										:disabled="pg.fee_status != 1"
										:rules="$rules.max(pg, 'payment_delay', 3)"
									/> 분
								</td>
								<td>
									<input
										v-model="pg.payment_per"
										class="box pa-5-10 width-fee "
										type="number"
										placeholder=""
										:disabled="pg.fee_status != 1"
										:rules="$rules.max(pg, 'payment_per', 3)"
									/> %
								</td>
								<td
								>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status == 1 ? 'bg-green color-white' : 'btn-default' "
										@click="pg.fee_status = 1"
									>mdi mdi-power-plug</v-icon>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status != 1 ? 'bg-red color-white' : 'btn-default' "
										@click="pg.fee_status = 0"
									>mdi mdi-power-plug-off</v-icon>
								</td>
							</tr>
							</tbody>
						</table>

						<h6>단일 결제 PG 설정</h6>
						<table
							class="table"
						>
							<thead>
							<tr>
								<th>PG 명</th>
								<th
									v-if="user.role == 'admin'"
								>PG 원가</th>
								<th>마진율</th>
								<th>공급가</th>
								<th>적용여부</th>
							</tr>
							</thead>
							<tbody>
							<tr
								v-for="(pg, index) in pg_sales_list2"
								:key="'pg_' + index"
							>
								<td>{{ pg.pg_name }}</td>
								<td
									v-if="user.role == 'admin'"
								>{{ pg.pg_fee }}%</td>
								<td>
									<input
										v-model="pg.fee_rate"
										type="number"
										:rules="[rules.demical(pg, 'fee_rate', { min: 2, max: 2})]"
										class="box pa-5-10 width-fee "
									/> %
								</td>
								<td>
									<input
										v-model="pg.total_rate"
										class="box pa-5-10 width-fee "
										type="number"
										disabled
									/> %
								</td>
								<td
								>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status == 1 ? 'bg-green color-white' : 'btn-default' "
										@click="setPgSingle(pg)"
									>mdi mdi-power-plug</v-icon>
									<v-icon
										class="pa-5 "
										:class="pg.fee_status != 1 ? 'bg-red color-white' : 'btn-default' "
										@click="pg.fee_status = 0"
									>mdi mdi-power-plug-off</v-icon>
								</td>
							</tr>
							</tbody>
						</table>
					</div>

					<div
						v-if="is_payment && item.agency_type =='A001003'"
						class="mb-50"
					>
						<h6>수기용 상품</h6>
						<ul
							class="justify-start gap-10 flex-row-wrap"
						>
							<li
								v-for="(product, p_index) in items_product"
								:key="'product_' + p_index"
								class="inline mr-10"
							>
								<input v-model="product.name" class="input-box mr-5" />
								<button
									v-if="items_product.length > 0 && p_index < items_product.length - 1"
									@click="items_product.splice(p_index, 1)"
								><v-icon class="color-red">mdi-close</v-icon></button>
								<button
									v-if="p_index == items_product.length - 1"
									class=""
									@click="items_product.push({ name: ''})"
								><v-icon class="color-blue">mdi-plus-circle</v-icon></button>
							</li>
						</ul>
					</div>

				</div>
			</div>
		</div>

		<div
			class="justify-center mt-auto"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
			<button
				class="box pa-10-20"
				@click="toList"
			>목록</button>
		</div>

		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>

		<Modal
			:is_modal="is_modal"
			:option="{}"
			:top="true"
			:bottom="true"

			title="비밀번호 초기화"
			content="비밀번호를 초기화 하시겠습니까?"
			width="360px"

			content_class="ptb-50"

			@click="doPassword"
			@close="clear"
			@cancel="clear"
		></Modal>
	</div>
</template>

<script>

import DaumPost from "@/components/Daum/DaumPost";
import Modal from "@/components/Modal";
import input_file from '@/components/InputFile'
import {Base64} from "js-base64";
export default {
	name: 'AgencyDetail'
	, components: {DaumPost, Modal, input_file}
	, props: ['Axios', 'user', 'codes', 'rules', 'date', 'TOKEN']
	,data: function(){
		return {
			program: {
				name: '영업점 상세정보'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				agency_type: ''
				, bank_code: ''
				, agency_upper: ''
				, business_type: ''
				, pg_code: ''
				, sales_pg_uid: ''
				, shop_info: ''
			}
			, item_pg: {
				uid: ''
			}
			, sales_pg_uid: ''
			, is_data_pick: false
			, is_modal: false
			, is_post: false
			, daum_config: {
				width: '360px'
			}
			, items_upper: []
			, item_logo_img: ''
			, item_upload_logo_img: {}
			, items_pg_list: [
				{payment_per: 0}
			]
			, items_pg_list2: [

			]
			, items_sms: []
			, items_virtual: []
			, is_wallet: false
			, is_payment: false
			, is_self: false
			, is_manage: false
			, is_api: false
			, items_summary: this.codes.shop_summary
			, items_product: [
				{ name: ''}
			]
			, item_deposit: {}
			, item_wallet: ''
			, items_ip: [
				{api_ip: ''}
			]
		}
	}
	,computed: {

		logo_img_name: function(){
			let name = '로고 이미지'
			return name
		}
		, text_fee_rate: function(){
			let t = 'PG 설정'
			if(this.item.agency_type == 'A001003'){
				t = 'PG 설정'
			}
			return t
		}
		, text_bg_pg: function(){
			let t = ''

			if(this.item.sales_pg_uid && this.item.sales_pg_uid == this.item_pg.uid){
				if(this.item_pg.pg_status == '1'){
					t = 'bg-success'
				}else{
					t = 'bg-error'
				}
			}else if(!this.item_pg.uid && this.item.sales_pg_uid === this.item_pg.uid){
				t = 'bg-success'
			}

			return t
		}
		, text_upper_sales_fee: function(){
			let t = 0
			t += Number(Number(this.item.sales_fee) + Number(this.item_pg.pg_fee ? this.item_pg.pg_fee : 0))
			this.items_upper.filter( (item) =>{
				if(this.item.agency_upper == item.uid){
					t += Number(item.sales_fee) + Number(item.upper_sales_fee ? item.upper_sales_fee : 0)
				}
			})
			return t
		}
		, text_upper_sales_fee_bank: function(){
			let t = 0
			t += Number(this.item.sales_fee_bank)
			this.items_upper.filter( (item) =>{
				if(this.item.agency_upper == item.uid){
					t += Number(item.sales_fee_bank) + Number(item.upper_sales_fee_bank ? item.upper_sales_fee_bank : 0)
				}
			})
			return t
		}
		, item_upper: function(){
			let t = {}
			this.items_upper.filter( (item) => {
				if(this.item.agency_upper == item.uid){
					t = item
					return
				}
			})
			return t
		}
		, pg_sales_list: function(){
			return this.items_pg_list.filter( (item) => {
				item.pg_div = 'multi'
				if(item.pg_type == 'P005001') {
					item.total_rate = Number(item.pg_fee) + Number(item.fee_rate) + Number(item.fee_upper ? item.fee_upper : 0) + Number(item.distributor_fee_rate ? item.distributor_fee_rate : 0)
					return item
				}
			})
		}
		, pg_sales_list2: function(){
			return this.items_pg_list2.filter( (item) => {
				item.pg_div = 'single'
				if(item.pg_type == 'P005001') {
					item.total_rate = Number(item.pg_fee) + Number(item.fee_rate) + Number(item.fee_upper ? item.fee_upper : 0) + Number(item.distributor_fee_rate ? item.distributor_fee_rate : 0)
					return item
				}
			})
		}
		, pg_bill_list: function(){
			return this.items_pg_list.filter( (item) => {
				if(item.pg_type == 'P005002'){
					item.total_rate =  Number(item.pg_fee) + Number(item.fee_rate) + Number(item.fee_upper ? item.fee_upper : 0) + Number(item.distributor_fee_rate ? item.distributor_fee_rate : 0)
					return item
				}
			})
		}
		, colspan_margin: function(){
			let t = 8
			if(this.user.role != 'admin'){
				t = 7
			}
			return t
		}
		, sales_type: function(){
			let t = 0

			if(this.is_wallet){
				t += 1
			}
			if(this.is_payment){
				t += 10
			}
			if(this.is_self){
				t += 100
			}
			if(this.is_manage){
				t += 1000
			}
			if(this.is_api){
				t += 10000
			}

			return t
		}
		, is_on_wallet: function(){
			let t = false
			if((this.item_upper.sales_type && this.$common.getPowNumber(this.item_upper.sales_type, 1))){
				t = true
			}
			return t
		}
		, is_on_payment: function(){
			let t = false
			if(this.item_upper.sales_type && this.$common.getPowNumber(this.item_upper.sales_type, 2)){
				t = true
			}
			return t
		}
		, is_on_api: function(){
			let t = false
			if(this.item_upper.sales_type && this.$common.getPowNumber(this.item_upper.sales_type, 5)){
				t = true
			}
			return t
		}
		, sum_per: function(){
			let t = 0
			let d = ''
			this.items_pg_list.filter( (item) => {
				if(item.fee_status == 1){
					t += item.payment_per ? Number(item.payment_per) : 0
				}
			})
			if(t != 100){
				d += ' 결제 비율을 확인하세요'
			}
			t += '%'
			return t + d
		}
		, names: function(){
			let t = ''
			this.items_product.filter((item, index) => {
				if(item){
					t += item.name
					if(index < this.items_product.length - 1){
						t += ','
					}
				}
			})
			return t
		}

		, shop_link: function(){

			let t = this.codes.live_url + encodeURI(Base64.encode(this.item.agency_id))

			if(this.item.shop_link){
				t = this.item.shop_link + '/Auth/Join/' + encodeURI(Base64.encode(this.item.agency_id))
			}
			if(this.item.agency_upper){
				this.items_upper.filter( (upper) => {
					if(upper.uid == this.item.agency_upper){
						if(upper.shop_link){
							t = upper.shop_link  + '/Auth/Join/' + encodeURI(Base64.encode(this.item.agency_id))
						}
					}
				})
			}
			return t
		}

		, ip_list: function(){
			let t = []

			this.items_ip.filter( (item) => {
				if(item.api_ip){
					t.push(item)
				}
			})

			return JSON.stringify(t)
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgency'
					,data: {
						shop_uid: this.$route.params.idx
					}
					, version: 'v2'
				})
				if(result.success){
					this.item = result.data.info
					this.item_deposit = result.data.deposit_member
					this.item.sales_fee_type = 0
					this.item.deposit_fee_type = 1

					if(result.data.sms_list){
						this.items_sms = result.data.sms_list
						this.items_virtual = result.data.virtual_list
					}

					this.is_wallet = this.$common.getPowNumber(this.item.sales_type, 1) ? true : false
					this.is_payment = this.$common.getPowNumber(this.item.sales_type, 2) ? true : false
					this.is_self = this.$common.getPowNumber(this.item.sales_type, 3)  ? true : false
					this.is_manage = this.$common.getPowNumber(this.item.sales_type, 4)  ? true : false
					this.is_api = this.$common.getPowNumber(this.item.sales_type, 5)  ? true : false

					this.item_upload_logo_img = {
						src: this.item.shop_logo_view
						, name: this.item.shop_logo_view
						, type: 'image'
					}
					this.item_logo_img = ''

					this.items_product = []
					if(result.data.info.shop_info){

						let d = result.data.info.shop_info?.split(',')

						if(d.length > 0){
							d.filter((product) => {
								if(product != ''){
									this.items_product.push({ name: product})
								}
							})
						}
					}
					if(this.items_product.length < 1){
						this.items_product.push({ name: ''})
					}
					this.item_wallet = result.data.wallet_info

					let api_ip = this.item.api_ip
					if(api_ip){
						this.items_ip = JSON.parse(api_ip)
					}
					await this.getAgencyUpper()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, save: async function(){
			try{
				this.$bus.$emit('on', true)

				if(this.item_logo_img){
					this.$set(this.item, 'item_logo_img', this.item_logo_img)
				}

				this.$set(this.item, 'sales_pg_list', JSON.stringify(this.items_pg_list))
				this.$set(this.item, 'sales_pg_list2', JSON.stringify(this.items_pg_list2))
				this.$set(this.item, 'shop_info', this.names)
				this.item.sales_type = this.sales_type
				this.item.api_ip = this.ip_list

				const result = await this.$request.init({
					method: 'post'
					, url: 'management/putAgency'
					, data: this.item
					, version: 'v2'
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,daumPost: function () {
			this.is_post = true
		}
		, addPost: function (call) {

			this.$set(this.item, 'shop_zip_code', call.zonecode)
			this.$set(this.item, 'shop_address', call.address)

			this.is_post = false
		}
		, toList: function(){
			this.$storage.push({ name: 'AgencyList', not_query: true})
		}
		, getAgencyUpper: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyUpper'
					,data: {
						agency_type: this.item.agency_type
					}
				})
				if(result.success){
					this.items_upper = result.data

					if(this.is_payment){
						await this.getPgList()
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,clear: function(){
			this.is_modal = false
		}
		,isPassword: function(){
			this.clear()
			this.is_modal = true
		}
		,doPassword: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgencyPasswordReset'
					,data: {
						uid: this.$route.params.idx
					}
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.clear()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, setFile2: function(e){
			console.log('setFile2', e)

			let self = this
			for(let file of e){
				console.log(`file` , file)
				this.item_logo_img = file

				const reader = new FileReader()
				let data = {
					name: file.name
					, size: file.size
					, type: file.type
				}

				reader.onload = function(e){
					console.log('reader.onload')
					data.src = e.target.result
					self.item_upload_logo_img = data
				}

				reader.readAsDataURL(file)
			}
		}
		, removeDelivery: async function(){
			if(this.item_logo_img){

				if(this.item.shop_logo){
					this.item_upload_logo_img = {
						src: this.item.shop_logo
						, name: this.item.shop_logo
						, type: 'image'
					}
					this.item_logo_img = {}
				}else{
					this.item_upload_logo_img = {}
					this.item_logo_img = {}
				}
			}else{
				if(confirm("삭제하시겠습니까?")){

					try{
						const result = await this.$request.init({
							method: 'post'
							,url: 'management/postShopLogoDelete'
							,data: {
								uid: this.item.uid
							}
						})

						if(result.success){
							this.item_upload_logo_img = {}
							this.item_logo_img = {}
						}else{
							this.$bus.$emit('notify', { type: 'error', message: result.message })
						}
					}catch (e) {
						console.log(e)
					}
				}
			}
		}
		, getPgList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAvailablePgList'
					,data: {
						agency_type: this.item.agency_type
						, upper_id: this.item_upper.agency_id
						, agency_id: this.item.agency_id
						, pg_div: 'multi'
					}
				})
				if(result.success){
					this.items_pg_list = result.data
					await this.getPgList2()
				}else{
					this.items_pg_list = []
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getPgList2: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAvailablePgList'
					,data: {
						agency_type: this.item.agency_type
						, upper_id: this.item_upper.agency_id
						, agency_id: this.item.agency_id
						, pg_div: 'single'
					}
				})
				if(result.success){
					this.items_pg_list2 = result.data
				}else{
					this.items_pg_list = []
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setPg: function(pg){
			this.item_pg = pg
			this.item.sales_pg_uid = pg.uid
		}
		, setFeeStatus: function(item, type){
			if(type){
				item.fee_status = 1
			}
		}
		, setBillFeeStatus: function(item, type){
			this.items_pg_list.filter( (pg) => {
				if(pg.pg_type == 'P005002') {
					pg.fee_status = 0
				}
			})
			if(type){
				item.fee_status = 1
			}
		}
		, setPgSingle: function(pg){
			this.items_pg_list2.filter( (item) => {
				item.fee_status = 0
			})

			pg.fee_status = 1
		}

		,clipBoard: function (val){
			const t = document.createElement("textarea");
			document.body.appendChild(t);
			t.value = val;
			t.select();
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy: function (){

			this.clipBoard(this.shop_link);
			alert('대리점 회원가입 바로가기 링크가 복사되었습니다.');
		}

		, postAgencyWallet: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgencyWallet'
					,data: {
						agency_id: this.item.agency_id
						, shop_uid: this.item.shop_uid
						, virtual_uid: this.item.upper_virtual_uid
						, agency_upper: this.item.agency_upper
						, agency_uid: this.item.agency_uid
					}
				})
				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postAgencyDepositWallet: async function(){
			if(confirm("해당 대리점의 입금 회원정보를 등록하고 입금 계좌를 발급합니다. 계속 하시겠습니까?")){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						,url: 'management/postAgencyDepositWallet'
						,data: {
							agency_id: this.item.agency_id
						}
					})
					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch(e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, postKey: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postApiKey'
					,data: {
						agency_id: this.item.agency_id
						, shop_uid: this.$route.params.idx
						, api_ip: this.ip_list
						, api_limit: this.item.api_limit
						, noti_url: this.item.noti_url
					}
					, version: 'v2'
				})
				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postRefresh: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postAgencyWalletRefresh'
					,data: {
						virtual_uid: this.item.upper_virtual_uid
						, shop_uid: this.item.shop_uid
						, wallet_uid: this.item_wallet.uid
						, account_id: this.item.agency_id
					}
				})

				if(result.success){
					this.item_wallet.balance = result.data.balance
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, addIp: function(){
			if(this.items_ip.length < 5){
				this.items_ip.push({
					api_ip: ''
				})
			}
		}

		, removeIp: function(index){
			this.items_ip.splice(index, 1)
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
.v-btn__content { color: #333 !important;}

.v-file-input__text--placeholder {
	color: #bbb !important;
	font-size: 14px;
}

.theme--light.v-icon {
	color: #bbb;
}

.item_close {
	position: absolute; right: 10px; top: 10px
}
</style>
